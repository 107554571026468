import { inject }            from 'aurelia-framework';
import { FormSchema }        from 'modules/bituminous-mixtures/mixtures/bm-tests/form-schema';
import { BMTestsRepository } from 'modules/bituminous-mixtures/mixtures/bm-tests/services/repository';
import { ViewBMTest }        from 'modules/bituminous-mixtures/mixtures/bm-tests/view';
import { Sector }            from 'modules/management/models/sector';
import { AppContainer }      from 'resources/services/app-container';

@inject(AppContainer, BMTestsRepository, FormSchema)
export class ViewBMLabTests extends ViewBMTest {

    constructor(appContainer, repository, formSchema) {
        super(appContainer, repository, formSchema);

        this.type     = 'lab';
        this.sectorId = Sector.BITUMINOUS_MIXTURES_LAB;
    }

}
