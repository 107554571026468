import { inject }            from 'aurelia-framework';
import { CreateBMTest }      from 'modules/bituminous-mixtures/mixtures/bm-tests/create';
import { FormSchema }        from 'modules/bituminous-mixtures/mixtures/bm-tests/form-schema';
import { BMTestsRepository } from 'modules/bituminous-mixtures/mixtures/bm-tests/services/repository';
import { CommentFormSchema } from 'modules/core/custom-components/comments/form-schema';
import { Sector }            from 'modules/management/models/sector';
import { AppContainer }      from 'resources/services/app-container';

@inject(AppContainer, BMTestsRepository, FormSchema, CommentFormSchema)
export class CreateBMInSituTest extends CreateBMTest {

    constructor(appContainer, repository, formSchema, commentSchema) {
        super(appContainer, repository, formSchema, commentSchema);

        this.type     = 'in-situ';
        this.sectorId = Sector.BITUMINOUS_MIXTURES_IN_SITU;
    }

}
